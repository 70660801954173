<template>
  <a-spin :spinning="false">
  <a-modal
      :title="'Möchten Sie sich ausloggen?'"
      v-model:open="logout"
      :closable="true"
      @cancel="logout = false"
      @ok="logoutFunction()"
      okText="Ok"
      cancelText="Abbrechen"
      centered>
    </a-modal>
    <div class="headerContent">
      <div class="headerContainer">
        <div class="imageContainer">
          <a href="https://mitteldeutsche-it.de/"><img src="../assets/mittelDeutsche_header_banner2.svg" width="230" height="45" style="width: 100%; max-width: 100%;"></a>
        </div>
        <div class="menuContainer">
          <a-menu class="menu" v-model:selectedKeys="current" mode="horizontal" :items="items" @select="handleClick" triggerSubMenuAction="click"></a-menu>
        </div>
        <div class="logoutContainer">
          <a-button class="logoutButton" size="large" type="primary" @click="logout=true">Ausloggen</a-button>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import logapi from '@/apis/logapis'
import { h } from 'vue'
import { notification, Button } from 'ant-design-vue'
import { ClockCircleOutlined } from '@ant-design/icons-vue'
export default {
    name: 'Header',
    component: {
    },
    data () {
        return {
          current: ['0'],
          logout: false,
          sessionCheckID: 0,
          items: [
          {
            key: '0',
            label: 'Dashboard',
            title: 'Dashboard',
            path: '/dashboard'
          },
          {
            key: '1',
            label: 'Neuigkeiten',
            title: 'Neuigkeiten',
            path: '/news'
          },/* ,
          {
            key: '3',
            icon: () => h(CaretDownOutlined),
            label: 'More',
            title: 'More',
            path: '/dashboard',
            children: [
              {
                label: 'Item1'
              },
              {
                label: 'Item2'
              },
            ]
          }, */
          ],
          stillCheckingSession: false,
          notificationID: 0
        }
    },
    mounted () {
      // this.checkReferer()
      this.$router.push({ path: '/dashboard'})
      this.initializeSessionCheck()
      document.addEventListener('click', () => { this.clickSessionCheck() })
    },
    beforeUnmount () {
      this.destroySessionCheck()
      document.removeEventListener('click', () => { this.clickSessionCheck() })
    },
    watch:{
      $route (){
          if(this.$route.path !== this.items[this.current].path){
            for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].path === this.$route.path) {
                this.current = [this.items[i].key]
              }
            }
          }
      }
    },
    methods: {
      handleClick (e) {
        this.$router.push({ path: e.item.originItemValue.path})
      },
      logoutFunction () {
        logapi.logout().then(response => {
          if (response.status === 200 ) {
            document.cookie = "session_token= ;SameSite=None; Secure"
            document.cookie = "sessionkey= ;path=/client/api;SameSite=None; Secure"
            document.cookie = "JSESSIONID= ;path=/client;SameSite=None; Secure"
            document.cookie = "BILLING-JSESSIONID= ;path=/;SameSite=None; Secure"
            document.cookie = "userid= ;SameSite=None; Secure"
            document.cookie = "account= ;SameSite=None; Secure"
            document.cookie = "authenticated= ;SameSite=None; Secure"
            document.cookie = "PHPSESSID= ;SameSite=None; Secure"
            this.$store.commit("setAuthentification", false)
            this.$store.commit("setAccountId", "")
            this.$store.commit("setStatusCloudUI",false)
            this.$store.commit("setEmail", "")
            this.$store.commit("setOtpValidated",false)
            this.$router.push({ path: '/'})
          }
        }).catch(error => {
          document.cookie = "session_token= ;SameSite=None; Secure"
          document.cookie = "sessionkey= ;path=/client/api;SameSite=None; Secure"
          document.cookie = "JSESSIONID= ;path=/client;SameSite=None; Secure"
          document.cookie = "BILLING-JSESSIONID= ;path=/;SameSite=None; Secure"
          document.cookie = "userid= ;SameSite=None; Secure"
          document.cookie = "account= ;SameSite=None; Secure"
          document.cookie = "authenticated= ;SameSite=None; Secure"
          document.cookie = "PHPSESSID= ;SameSite=None; Secure"
          this.$store.commit("setAuthentification", false)
          this.$store.commit("setAccountId", "")
          this.$store.commit("setStatusCloudUI",false)
          this.$store.commit("setEmail", "")
          this.$store.commit("setOtpValidated",false)
          this.$router.push({ path: '/'})
        })
      },
      initializeSessionCheck() {
        var notificationID = this.sessionCheckID.toString()
        this.notificationID = this.sessionCheckID.toString()
        this.checkSessionOnce(notificationID)
        this.sessionCheckID = setInterval(this.checkSessionOnce, 60000, notificationID);
      },
      destroySessionCheck() {
        clearInterval(this.sessionCheckID)
      },
      checkSessionOnce(notificationID){
        logapi.checkSession().then(response => {
            if (response.data === true) {
              notification.warn({
                message: "Die Sitzung läuft bald ab.",
                description: "Diese Sitzung hält weniger als 5 Minuten. Wenn Sie die Sitzung verlängern möchten, klicken Sie bitte auf die Schaltfläche. Wenn Sie keine Erweiterung benötigen, können Sie diese Benachrichtigung schließen oder ignorieren.",
                duration: 0,
                btn: h(
                  Button,
                  {
                    size: 'small',
                    onClick: () => {notification.close(notificationID), logapi.refreshSession()}, // call api to extend session
                  },
                  () => 'Sitzung verlängern',
                ),
                key: notificationID,
                class: "sessionNotification",
                icon: h(ClockCircleOutlined, { style: 'color: #d02c2a' }),
              })
            }
          }).catch(error => {
              console.log(error)
          })
      },
      clickSessionCheck () {
        if (!this.stillCheckingSession) {
          this.stillCheckingSession = true
          setTimeout(this.checkSessionOnClick, 5000)
        }
      },
      async checkSessionOnClick () {
        await logapi.checkValidSession().then(response => {
          if (response.data === true) {
            logapi.refreshSession()
            notification.close(this.notificationID)
            this.stillCheckingSession = false
          }else{
            this.logoutFunction()
          }
          // this.stillCheckingSession = false
        }).catch(error => {
          console.log(error)
        })
      },
      // checkReferer(){
      //   logapi.getReferer().then(response =>{
      //     let refererHost = response.data.referer
      //     if(refererHost.length !== 0 && refererHost.includes("cloud.mitteldeutsche-it.de")){
      //       logapi.logout()
      //     }
      //   }).catch(error => {
      //     console.log(error)
      //   })
      // }
    }
}
</script>

<style scoped>
.headerContent {
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  height: 100px;
  background-color: white;
  z-index: 1000;
  top: 0px;
  left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.headerContainer {
  display: flex;
  height: 60%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}
.logoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
}
.menuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-left: 40px;
  width: 320px;
}
.menu {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5em;
  color: #333;
}
.imageContainer {
  display: flex;
  width: 230px;
}
.logoutButton {
  font-weight: bold;
  border-radius: 4px;
}

@media only screen and (max-width: 910px) {

  .logoutContainer{
    width: 50px;
  }
  .menuContainer {
    width: 400px;
  }
}

@media only screen and (max-width: 760px) {
  .logoutContainer{
    width: 40px;
  }
  .menuContainer {
    width: 40px;
    margin-left: 10px;
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1000px) {
  .menuContainer {
    width: 320px;
    margin-left: 10px;
    margin-right: 40px;
  }
}
</style>
